import collaboratorOne from '../assets/img/collaborator_1.png'
import collaboratorTwo from '../assets/img/collaborator_2.png'
import collaboratorThree from '../assets/img/collaborator_3.png'
import collaboratorFour from '../assets/img/collaborator_4.png'
import collaboratorFive from '../assets/img/collaborator_5.png'
import collaboratorSix from '../assets/img/collaborator_6.png'
import collaboratorSeven from '../assets/img/collaborator_7.png'
import collaboratorEight from '../assets/img/collaborator_8.png'
import collaboratorNine from '../assets/img/collaborator_9.png'
import collaboratorTen from '../assets/img/collaborator_10.png'

const collaboratorsData = [
  {
    name: 'Francieli Camargo',
    role: 'Operadora de Seguros',
    image: collaboratorOne,
  },
  {
    name: 'Simone da Silva',
    role: 'Operadora de Seguros',
    image: collaboratorTwo,
  },
  // {
  //   name: 'Jeniffer Heidrich',
  //   role: 'Estagiária',
  //   image: collaboratorThree,
  // },
  {
    name: 'Rosiane Nogueira Araújo',
    role: 'Operadora de Seguros',
    image: collaboratorFour,
  },
  // {
  //   name: 'Alex Sandro Moreira',
  //   role: 'Operador de Seguros',
  //   image: collaboratorTen,
  // },
  {
    name: 'Elba Marinho',
    role: 'Operadora de Seguros',
    image: collaboratorFive,
  },
  {
    name: 'Vitória Cristiana Nascimento',
    role: 'Assistente Administrativo- Sinistro e parcelas',
    image: collaboratorSix,
  },
  {
    name: 'Soraya Furtado da Rosa',
    role: 'Head de Seguros',
    image: collaboratorSeven,
  },
  {
    name: 'Filippe Telmo',
    role: 'Vendedor de Garantia Estendida e Seguros',
    image: collaboratorEight,
  },
  {
    name: 'Márcia Carvalho',
    role: 'Coordenadora',
    image: collaboratorNine,
  },
]

export default collaboratorsData
